<template>
    <v-card dark width="350">
        <v-app-bar>
            Vlastní filtr
            <v-spacer></v-spacer>
            <v-btn @click="reset" text>
                <v-icon left>filter_alt</v-icon>
                Vyčistit
            </v-btn>
            <v-btn icon @click="close">
                <v-icon>close</v-icon>
            </v-btn>
        </v-app-bar>
        <v-card-text>
            <div class="d-flex">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-switch
                                    class="mt-1 mb-1"
                                    inset
                                    hide-details
                                    color="red"
                                    value="yes"
                                    v-model="calamityFilter"
                                    label="Kalamita"
                            ></v-switch>
                        </div>
                    </template>
                    Skryje neuzavřené události na kterých již neprobíhá činnost a nastaví typy událostí na operační
                </v-tooltip>
                <!--                <v-spacer></v-spacer>-->
                <!--                <v-btn outlined rounded>+ Oper</v-btn>-->
            </div>
            <div>
                <v-text-field v-model="filters['search']" label="Text" clearable></v-text-field>
            </div>
            <div v-for="(item, key) in allowedFilters" :key="key">
                <v-select v-model="filters[key]" :items="getItems(item.items)" :label="item.name" multiple
                          clearable></v-select>
            </div>

        </v-card-text>
    </v-card>
</template>

<script>

import {mapState} from "vuex";
import {cloneDeep, isNaN, map, orderBy, toNumber} from "lodash";

export default {
    name: 'event-filters',
    data() {
        return {
            f: {},
        }
    },
    beforeMount() {
        this.$store.dispatch('events/setFilterByKey', 'custom');
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            settings: state => state.settings,
            customFilters: state => state.events.customFilters,
        }),
        allowedFilters() {
            return this.settings.filters.allowed;
        },
        filters: {
            get() {
                return this.customFilters;
            },
            set(values) {
                this.$store.commit('events/SET_CUSTOM_FILTERS', values);
            }
        },
        calamityFilter: {
            get() {
                return this.customFilters?.calamity;
            },
            set(value) {
                const filters = cloneDeep(this.filters);
                if (value) {
                    filters.calamity = 'yes';
                    filters.eventCategoryTypes = ["event", "call", "chat"];
                    this.filters = filters;
                } else {
                    filters.calamity = null;
                    filters.eventCategoryTypes = [];
                    this.filters = filters;
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
        getItems(items) {
            items = map(items, (value, key) => {
                const numericKey = toNumber(key);
                return {
                    text: value,
                    value: isNaN(numericKey) ? key.toLowerCase() : numericKey,
                }
            });
            return orderBy(items, ['text']);
        },
        reset() {
            this.$store.commit('events/SET_CUSTOM_FILTERS', {});
        }
    },
}

</script>
