import axios from 'axios';
import store from '../store/index'
import {appBus} from "@/main";

export default {
    async getUrl() {
        return store.state.setup.endpoints.apiReverseGeoUrl;
    },
    async postRequest(coordinates, data) {
        const url = await this.getUrl();
        try {
            return await axios.post(url + '/' + coordinates[0] + '/' + coordinates[1], data);
        } catch (error) {
            console.error('Error while posting data', error);
            throw error;
        }
    },
    async reverse(coordinates, event = null, updateEvent = false, point = null) {
        let data = {};
        if (event) {
            data.eventTerrainId = event.eventTerrainId;
            data.eventUuid = event.uuid;
            data.updateEvent = updateEvent;
            data.addressData = point;
        }
        try {
            const response = await this.postRequest(coordinates, data);
            appBus.$emit('onReverseResult', response.data);
            return response.data;
        } catch (error) {
            console.error('Error in reverse method', error);
            throw error;
        }
    },
}