import Vue from 'vue'

import * as Sentry from "@sentry/vue";
import VueHtml2Canvas from 'vue-html2canvas';

if (process.env.VUE_APP_SENTRY !== '0') {
    Sentry.init({
        Vue: Vue,
        environment: process.env.VUE_APP_ENV,
        dsn: process.env.VUE_APP_SENTRY_DNS,
        tracesSampleRate: 1.0,
        logErrors: true,
    });
}
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import vuetify from './plugins/vuetify';
import App from './App.vue'
import {uuid} from "vue-uuid";
import store from './store/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import VueNoty from 'vuejs-noty'
import numFormat from 'vue-filter-number-format';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMoment from 'vue-moment'
import Fragment from 'vue-fragment'
import VueTruncate from 'vue-truncate-filter';
import AudioRecorder from 'vue-audio-recorder'
import notificationPlugin from './plugins/notifications.js'
import Viewer from 'v-viewer'
import VueClipboard from 'vue-clipboard2'
// import PubNubVue from 'pubnub-vue';
import {Model} from 'vue-api-query'
import VueTheMask from 'vue-the-mask'
import AudioVisual from 'vue-audio-visual'
import numeral from 'numeral';
import '@/filters/phone';

import {abilitiesPlugin} from '@casl/vue';
// import ability from './services/ability';

import 'viewerjs/dist/viewer.css'
import './scss/_custom.scss'

Vue.use(abilitiesPlugin, store.getters.ability)

import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

// Components context items and dialogs
// Todo: make it dynamic
const SapCard = () => import("./components/sap/Card");
Vue.component('SapCard', SapCard);
const SapContextMenuItem = () => import("./components/sap/ContextMenuItem");
Vue.component('SapContextMenuItem', SapContextMenuItem);
const SapDevTab = () => import("./components/sap/DevTab");
Vue.component('SapDevTab', SapDevTab);
const EventDestinationCard = () => import("./components/eventDestination/Card");
Vue.component('EventDestinationCard', EventDestinationCard);
const EventDestinationContextMenuItem = () => import("./components/eventDestination/ContextMenuItem");
Vue.component('EventDestinationContextMenuItem', EventDestinationContextMenuItem);

const MessageMedia = () => import("./components/panel/sidebar/event/Media");
Vue.component('MessageMedia', MessageMedia);

const ExploreCard = () => import("./components/explore/Card");
Vue.component('ExploreCard', ExploreCard);
const ExploreContextMenuItem = () => import("./components/explore/ContextMenuItem");
Vue.component('ExploreContextMenuItem', ExploreContextMenuItem);

// import VueMobileDetection from "vue-mobile-detection";
// Vue.use(VueMobileDetection);

// numeral.register('format', 'distance', {
//     regexps: {
//         format: /(dt)/,
//         unformat: /(dt)/
//     },
//     format: function (value, format, roundingFunction) {
//         let space = numeral._.includes(format, ' dt') ? ' ' : '',
//             output,
//             distance;
//
//         if (value >= 1) {
//             distance = 'km';
//         } else {
//             value = value * 1000;
//             distance = 'm';
//         }
//
//         output = numeral._.numberToFormat(value, format, roundingFunction);
//
//         return output + space + distance;
//     },
//     unformat: function (string) {
//         return numeral._.stringToNumber(string);
//     }
// });

// noinspection RegExpRedundantEscape
numeral.register('format', 'phone', {
    regexps: {
        format: /\+?N?[. -]?\(?NNN\)?[. -]?NNN[. -]?NNNN/
    },
    format: function (value, formatString) {
        function normalize(phoneNumber) {

            // noinspection RegExpRedundantEscape
            return phoneNumber.toString().replace(
                /^[+\d{1,3}\-\s]*\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                "$1$2$3"
            );
        }

        function format(phoneNumber, formatString) {
            phoneNumber = normalize(phoneNumber);
            for (var i = 0, l = phoneNumber.length; i < l; i++) {
                formatString = formatString.replace("N", phoneNumber[i]);
            }
            return formatString;
        }

        return format(value, formatString);
    }
});

const moment = require('moment');
require('moment/locale/cs');

Vue.filter('numFormat', numFormat(numeral));
Vue.use(VueTheMask);
Vue.use(VueHtml2Canvas);
Vue.use(notificationPlugin, {});
Vue.use(VueNoty, {
    timeout: 3000,
    progressBar: true,
    layout: 'topCenter'
});
Vue.use(VueMoment, {moment});
Vue.use(VueAxios, axios);
Vue.use(VueLodash, {lodash: lodash});
Vue.use(Fragment.Plugin);
Vue.use(VueTruncate);
Vue.use(AudioRecorder);
Vue.use(require('vue-shortkey'));
Vue.use(Viewer);
Vue.use(VueClipboard);

import PubNub from 'pubnub';
const pubnub = new PubNub({
    subscribeKey: process.env.VUE_APP_PUBNUB_SUB,
    publishKey: process.env.VUE_APP_PUBNUB_PUB,
    ssl: true,
    uuid: uuid.v4()
})
Vue.prototype.$pubnub = pubnub;


axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.auth.token;
if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
        type: 'SET_TOKEN',
        token: store.state.auth.token
    })
}

navigator.serviceWorker.addEventListener('message', event => {
    if (event.data === 'REQUEST_AUTH_TOKEN') {
        const token = store.state.auth.token;
        if (token) {
            navigator.serviceWorker.controller.postMessage({type: 'SET_TOKEN', token: token});
        }
    }
});

Vue.use(AudioVisual)

Vue.config.productionTip = false;

Model.$http = axios;

export const appBus = new Vue();

const messages = {
    cs: require('./messages/cs.json'),
}

const i18n = new VueI18n({
    locale: 'cs',
    messages,
    pluralizationRules: {
        'cs': function (choice) {
            if (choice === 0)
                return 0;

            if (choice === 1)
                return 1;

            if (choice <= 4)
                return 2;

            return 3;
        }
    }
})

new Vue({
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');

window.addEventListener("pagehide", event => {
    if (event.persisted) {
        window.console.log('PAGE HIDE')
        /* the page isn't being discarded, so it can be reused later */
    }
}, false);

window.addEventListener('load', function() {
    window.history.pushState({}, '')
})

window.addEventListener('popstate', function() {
    window.history.pushState({}, '')
})

window.onpagehide = event => {
    if (event.persisted) {
        window.console.log('ON PAGE HIDE')
        /* the page isn't being discarded, so it can be reused later */
    }
}

Vue.config.errorHandler = function (err, vm, info) {
    if (info !== 'beforeDestroy hook (Promise/async)') {
        throw err;
    }
}

