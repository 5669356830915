<template>
    <vl-layer-group v-if="isCurrentEventActive" :zIndex="105">
        <event-destination :screenShotActive="screenShotActive" v-bind:event="event"></event-destination>
        <layer-event-geo v-bind:event="event"></layer-event-geo>
        <layer-event-poises :screenShotActive="screenShotActive" v-bind:event="event"></layer-event-poises>
        <layer-event-messages :opacity="screenShotActive ? 0 : 1" v-bind:event="event"></layer-event-messages>
        <layer-link-event :opacity="screenShotActive ? 0 : 1" v-if="showLinkEventLayer" v-bind:event="event"
                          v-bind:target-coordinates="targetCoordinates"></layer-link-event>

        <layer-job :screenShotActive="screenShotActive" v-for="(job, i) in jobs" :key="i" v-bind:job="job"
                   v-bind:event="event"></layer-job>
    </vl-layer-group>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import {includes} from "lodash";
import EventDestination from '../../../eventDestination/MapLayer'
import LayerLinkEvent from '../../../linkEvent/MapLayer'
import LayerEventPoises from '../EventPoises'
import LayerEventGeo from '../../../geo/Geo'
import LayerEventMessages from './Messages'
import LayerJob from "@/components/map/layers/event/Job.vue";

export default {
    name: 'layer-events',
    components: {
        LayerJob,
        LayerEventGeo,
        LayerEventPoises,
        LayerEventMessages,
        EventDestination,
        LayerLinkEvent,
    },
    props: {
        event: Object,
        uuids: Array,
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            activeEventId: 'getActiveEventId',
            activeEvent: 'getActiveEvent',
            targetTooltip: 'targetTooltip',
        }),
        visible() {
            return includes(this.uuids, this.event.uuid);
        },
        jobs() {
            return this.$store.getters['eventJobs/jobs'](this.event.uuid)
        },
        eventCategory() {
            return this.$store.getters['eventCategories/getEventCategoryById'](this.event.eventCategoryId)
        },
        isCurrentEventActive() {
            return this.isActiveEvent && this.activeEventId === this.event.uuid;
        },
        showLinkEventLayer() {
            return this.event && this.eventCategory.type === 'call' && this.targetCoordinates;
        },
        targetCoordinates() {
            return this.event.targetLongitude && this.event.targetLatitude ? [parseFloat(this.event.targetLongitude), parseFloat(this.event.targetLatitude)] : false;
        },
    },
    methods: {
        ...mapActions({
            setTrains: 'trainLocation/setTrains',
            updateTrains: 'trainLocation/updateTrains',
        })
    },
    mounted() {
        // show train information if train defined
        if (this.event !== undefined
            && this.event.data !== null
            && this.event.data !== undefined
            && this.event.data.trainNumber !== undefined
            && this.event.data.trainNumber != 0
        ) {
            this.setTrains(null, this.event.data.trainNumber);
            setInterval(function () {
                this.updateTrains();
            }.bind(this), 30000);
        }
    },
}
</script>
