<template>
    <v-card v-if="isEnabled" class="train-location-wrapper" min-width="460" ref="print">
        <v-toolbar color="default" dark dense>
            <v-toolbar-title>
                <small v-if="isTrain">
                    {{ train.properties.title }}
                </small>
                <small v-else>
                    Lustrace vlaku - nahrávám...
                </small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pl-3">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" fab x-small @click="reloadPosition">
                            <v-icon size="23">refresh</v-icon>
                        </v-btn>
                    </template>
                    Aktualizace polohy vlaku
                </v-tooltip>
                <capture-screen-button v-if="$can('manage', 'Event')" fab x-small :el="$refs.print"
                                       :message-type="messageType" @onSend="onCaptureSend"></capture-screen-button>
                <v-btn fab x-small color="error" @click="closeCard">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card v-if="train !== null  && train !== undefined" max-height="75vh" class="scrollable">
            <v-card-text>
                <ul class="list-unstyled pa-0">
                    <li class="row row--dense">
                        <span class="col-3 label">Dopravce</span>
                        <span class="col-9 text-right">{{ train.properties.dopravce }}</span>
                    </li>
                    <li class="row row--dense">
                        <span class="col-2 label">Trasa</span>
                        <span class="col-10 text-right">{{ train.properties.vychozi_stanice }} -> {{
                                train.properties.cilova_stanice
                            }}</span>
                    </li>
                    <li class="row row--dense">
                        <span class="col-2 label">Poloha</span>
                        <span class="col-10 text-right font-weight-bold">{{ train.properties.vyskyt }}</span>
                    </li>
                    <li class="row row--dense">
                        <span class="col-3 label">Aktualizace</span>
                        <span class="col-9 text-right">{{ train.properties.updated }}</span>
                    </li>
                    <li class="row row--dense grey">
                        <span class="col-12 label">Kontakt na vl. četu</span>
                    </li>
                    <li class="row  row--dense">
                        <contact-list
                                v-show="contacts.length > 0"
                                ref="contactList"
                                dense
                                can-send
                                hideHeader
                                :autocomplete="false"
                                :items="contacts"
                                class="col-12"
                        >
                        </contact-list>
                        <span v-show="contacts.length === 0">Kontakty nejsou k dispozici</span>
                    </li>
                    <li class="row row--dense grey">
                        <span class="col-12 label">Souprava</span>
                    </li>
                    <li class="row row--dense">
                        <span class="col-4 label">Délka / Hmotnost</span>
                        <span class="col-8 text-right">{{ train.properties.delka }} m / {{ train.properties.hmotnost }} t</span>
                    </li>
                    <li class="row row--dense">
                        <span class="col-4 label">Počet vozů / náprav</span>
                        <span class="col-8 text-right">{{ train.properties.vozy }} / {{
                                train.properties.pocet_naprav
                            }}</span>
                    </li>
                    <li class="row row--dense" v-if="train.properties.pocet_cestujicich" >
                        <span class="col-4 label">Počet cestujících</span>
                        <span class="col-8 text-right">{{ train.properties.pocet_cestujicich }}</span>
                    </li>
                    <li class="row row--dense grey">
                        <span class="col-5 label">Hnací vozidla</span>
                        <span class="col-7 label text-right">řada/číslo/trakce HV</span>
                    </li>
                    <li v-for="engine in train.properties.train_engines" :key="engine.title" class="row row--dense">
                        <span class="col-5 label">{{ engine.funkce_hv_popis }}</span>
                        <span class="col-7 label text-right">{{ engine.title }}</span>
                    </li>
                    <li class="row row--dense grey" v-if="hasSpecialCargo">
                        <span class="col-12 label">Specifický náklad/Mimořádnosti</span>
                    </li>
                    <li class="row row--dense" v-if="train.properties.mimoradna_zasilka === true">
                        <span class="col-4 label">Mimořádná zásilka</span>
                        <span class="col-8 text-right">{{ specialCargo }}</span>
                    </li>
                    <li class="row row--dense" v-if="train.properties.ziva === true">
                        <span class="col-4 label">Živý náklad</span>
                        <span class="col-8 text-right">{{ yesNo(train.properties.ziva) }}</span>
                    </li>
                    <li class="row row--dense" v-if="train.properties.nebezpecne_veci === true">
                        <span class="col-4 label">Nebezpečné věci</span>
                        <span class="col-8 text-right">{{ train.properties.mimoradnost.nv }}</span>
                    </li>
                    <li class="row row--dense" v-if="train.properties.mimoradnost.jina !== null">
                        <span class="col-4 label">Jiná mimořádnost</span>
                        <span class="col-8 text-right">{{ train.properties.mimoradnost.jina }}</span>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import ContactList from "@/components/ui/contactList/ContactList";
import {forEach, some} from "lodash";

export default {
    name: "map-card-train-location",
    components: {
        ContactList,
        CaptureScreenButton: () => import("@/components/ui/CaptureScreenButton"),
    },
    data() {
        return {
            messageType: 'X23',
        }
    },
    computed: {
        ...mapState({
            tr: state => state.trainLocation.tr,
        }),
        ...mapGetters('trainLocation', {
            train: 'getTrain',
            isEnabled: 'isEnabled',
        }),
        isTrain() {
            return this.train !== undefined && this.train !== null;
        },
        contacts() {
            let contacts = [];
            if (this.isTrain && this.train.properties) {
                forEach(this.train.properties.kontakt, (kontakt) => {

                    const number = this.sanitizeNumber(kontakt);
                    if (some(contacts, { 'number': number })) {
                        return;
                    }

                    let name = "strojv. ";
                    name += this.train.properties.druh_vlaku ? this.train.properties.druh_vlaku : '';
                    name += this.train.properties.vlak_cislo ? this.train.properties.vlak_cislo : '';
                    name += this.train.properties.nazev ? ' ' + this.train.properties.nazev : '';

                    contacts.push({name: 'Strojvedoucí', number: number, send: {name: name}})
                });

                forEach(this.train.properties?.kontakty_vlakova_ceta, (kontakt) => {

                    const number = this.sanitizeNumber(kontakt.telefon);
                    if (some(contacts, { 'number': number })) {
                        return;
                    }

                    let name = kontakt.funkce_na_vlaku_popis + ' ';
                    name += this.train.properties.druh_vlaku ? this.train.properties.druh_vlaku : '';
                    name += this.train.properties.vlak_cislo ? this.train.properties.vlak_cislo : '';
                    name += this.train.properties.nazev ? ' ' + this.train.properties.nazev : '';

                    contacts.push({name: kontakt.funkce_na_vlaku_popis, number: number, send: {name: name}})
                });
            }
            return contacts;
        },
        specialCargo() {
            const mimoradnost = this.train.properties.mimoradnost;
            let description = '';
            if (mimoradnost.vojenska_preprava !== null) {
                description += mimoradnost.vojenska_preprava + ' ';
            }
            if (mimoradnost.plm !== null) {
                description += mimoradnost.plm;
            }
            return description;
        },
        hasSpecialCargo() {
            const properties = this.train.properties;
            if (properties.ziva === true || properties.nebezpecne_veci === true || properties.mimoradna_zasilka === true) {
                return true;
            }
            const mimoradnosti = properties.mimoradnost;
            if (mimoradnosti.vojenska_preprava !== null || mimoradnosti.plm !== null
                || mimoradnosti.nv !== null || mimoradnosti.jina !== null) {
                return true;
            }
            return false;
        },
    },
    methods: {
        sanitizeNumber(phoneNumber) {
            let numberAfterReplacement = phoneNumber.replace(/^00/, '+');
            return numberAfterReplacement.replace(/^\+420/, '');
        },
        ...mapActions('trainLocation', {
            closeCard: 'close',
            updateTrains: 'updateTrains',
        }),
        ...mapActions('map', {
            extendToArray: 'extendToArray',
            setAutoFocus: 'setAutoFocus',
        }),
        yesNo(value) {
            return value === true ? 'Ano' : 'Ne';
        },
        confirmation(value) {
            return value === true ? 'potvrzeno' : 'nepotvrzeno';
        },
        onCaptureSend() {
            if (this.contacts.length > 0)
                this.$refs.contactList.addFirstToEvent();
        },
        reloadPosition() {
            this.updateTrains();
            if (this.isTrain) {
                this.setAutoFocus(false);
                this.extendToArray(this.getExtentCoordinates());
            }
        },
        getExtentCoordinates() {
            let coordinates = [];
            coordinates.push(this.train.properties.current_gps);
            // if (this.train.properties.begin_track_gps)
            //     coordinates.push(this.train.properties.begin_track_gps);
            // if (this.train.properties.end_track_gps)
            //     coordinates.push(this.train.properties.end_track_gps);
            return coordinates;
        }
    },
    watch: {
        isTrain(value) {
            if (value) {
                this.setAutoFocus(false);
                this.extendToArray(this.getExtentCoordinates());
            }
        },
    },
}
</script>

<style scoped>

.label {
    text-align: left;
    font-weight: bold;
}

.list-unstyled {
    list-style: none;
}

.text-right {
    text-align: right;
}

</style>
